.App {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
}

.column {
  flex: 1;
  margin: 10px;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.column h1, .column h2 {
  margin-top: 0;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar li {
  margin-bottom: 10px;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

label input {
  margin-left: 10px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}